<template>
  <div class="row-main">
    <!-- m-swiper -->
    <div class="m-swiper">
      <swiper :options="swiperOption" ref="mSwiper" class="swiper">
        <!-- slides -->
        <swiper-slide v-for="(item, index) of swiperList" :key="index" class="item">
          <div class="con">
            <div class="top" :style="{
                'background-color': item.color || '#0380FF',
                'background-image': `url(${item.iconImage!==undefined?item.iconImage:''})`,
              }">
              <div class="right">
                <h3>{{ item._id || "" }}</h3>
                <div class="num">{{ item.total || 0 }}{{item.swiperTypeName}}</div>
              </div>
            </div>
            <div class="bottom">
              <div class="value">{{ item.total_depth || 0 |filterstwo}}{{item.uint}}</div>
            </div>
          </div>
        </swiper-slide>
        <!-- Optional controls -->
      </swiper>
      <div class="swiper-button-prev" @click="prevHandle"></div>
      <div class="swiper-button-next" @click="nextHandle"></div>
    </div>
    <!-- m-swiper end -->
    <div class="m-content">
      <el-row>
        <el-col :span="12">
          <div class="m-box m-map">
            <div class="top">
              <h2>电子地图</h2>
              <!-- <div class="right">
                <g-tabs @change="changeHandle" :current="mapType" :list="tabList" />
              </div> -->
            </div>
            <!-- 天地地图 -->
            <div class="map-container" style="width: 100%;height: 100%;">
              <tdt-map @init="initMap" :controls="controls" :scrollWheelZoom="true" :center="center" :zoom="9" style="width: 100%;height: 100%;">
                <tdt-control position="topright" :visible="visible"></tdt-control>
                <tdt-marker v-for="(item, index) of positions" :key="index" :position="item.position" :icon="{iconUrl: item.icon ,iconAnchor: [20,40]}" :extData="item" @click="openInfowindow"></tdt-marker>
                <tdt-infowindow :minWidth="320*scale" :target="infowindow.target" :content="infowindow.content" @close="infowindow.target = null">
                </tdt-infowindow>
              </tdt-map>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="m-box m-table m-device-echart">
            <div class="top">
              <h2>总设备:{{ totalDevice }}台</h2>
            </div>
            <div class="con">
              <el-row>
                <el-col :span="12" class="chart" style="padding:0 0.9375rem">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </el-col>
                <el-col :span="12">
                  <div style="height:28px">
                    <div class="typeDiv1">类型</div>
                    <div class="typeDiv3">在线</div>
                    <div class="typeDiv2">离线</div>
                    <div class="typeDiv4">离场</div>
                    <div style="clear:both"></div>
                  </div>
                  <el-table :data="deviceData" header-row-class-name="thead" :show-header="false" style="width: 100%">
                    <el-table-column class-name="first" label="类型" min-width="40%">
                      <template slot-scope="scope"><span :style="{ background: scope.row.color }" class="tip"></span><span class="value">{{
                          scope.row.name
                        }}</span></template>
                    </el-table-column>
                    <el-table-column min-width="20%" prop="onLine" label="在线">
                    </el-table-column>
                    <el-table-column prop="offLine" label="离线" min-width="20%" class-name="col-2">
                    </el-table-column>
                    <el-table-column prop="deviceLeave" label="离场" min-width="20%" class-name="col-2">
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="m-box m-week-echart">
            <div class="top">
              <h2>日产统计</h2>
            </div>
            <div class="con">
              <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="m-box m-table">
            <div class="top">
              <h2>实时数据</h2>
            </div>
            <div class="con">
              <!-- m-swiper -->
              <div class="m-swiperb1">
                <div class="tit">
                  <div class="item">类型</div>
                  <div class="item">桩号</div>
                  <div class="item">作业量</div>
                  <div class="item">时间</div>
                </div>
                <swiper :options="swiperOption1" ref="mSwiper1" class="swiper1">
                  <swiper-slide v-for="(item, index) of tableData" :key="index" class="item">
                    <div class="gorup">
                      <div class="i1 i1pileName" :title=item.deviceTypeName>{{item.deviceTypeName|devicefilter}}</div>
                      <!-- swiperShigong  swiperRightBot -->
                      <div class="i1 i1pileName" :class="item._id==undefined?'swiperShigong':'swiperRightBot'" :title=item.pileDescribe>{{item.pileDescribe|pileDescribefilter}}</div>
                      <div class="i1 i1pileName">{{item.depth|depthfilter}}</div>
                      <div class="i1 i1pileName">{{item.ts|datafilter}}</div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <!-- m-swiper end -->
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import gTabs from "@/components/gTabs";
import { historySum, queryDevices, newest, analysis } from '@/api/api';
import $ from 'jquery'

export default {
  name: "Index",
  components: {
  },
  data() {
    return {
      //天地图数据
      positions: [
        {
          type: '',
          devicekey: '',
          itemid: "0+1_AS101",
          status: 0,
          pulp: 0,
          ash: 0,
          icon: require("@/assets/images/map/b4.png"),
          position: [],
        },
      ],
      visible: true,
      copyright: true,
      infowindow: {
        content: "123",
        target: [-999, -999]
      },
      center: [120.21887, 30.3275],
      controls: [{
        name: "MapType",
        mapTypes: [
          {
            title: "卫星",
            icon: " http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellite.png",
            layer: "TMAP_SATELLITE_MAP"
          },
          {
            title: "地图", //地图控件上所要显示的图层名称
            icon: "http://api.tianditu.gov.cn/v4.0/image/map/maptype/vector.png", //地图控件上所要显示的图层图标（默认图标大小 80x80）
            layer: "TMAP_NORMAL_MAP" //地图类型，在原天地图api中以window.TMAP_NORMAL_MAP表示，此处为字符串
          },

        ]
      }],
      //天地图
      machineType: '',
      machineKey: '',
      type: 'default',
      iconImag: '',
      tab: '',
      swiperOption: {
        slidesPerView: 6,
      },
      swiperOption1: {
        loop: true,
        initialSlide: 0,
        direction: 'vertical',
        slidesPerView: 6,
        speed: 2000,
        observer: true,
        observeParents: true,
        autoplay: 1000,
        // disableOnInteraction: false,
      },
      scale: 1,
      swiperList: [{
        deviceType: "JBZ",
        total: 0,
        total_depth: 0,
        _id: "搅拌桩"
      }],
      deviceData: [],
      tableData: [],
      dataset: [],
      maxAmount: 0,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mSwiper.swiper;
    },
    swiper1() {
      return this.$refs.mSwiper1.swiper;
    },
    totalDevice() {
      let value = 0;
      this.deviceData.map((item) => {
        value += item.onLine + item.offLine + item.deviceLeave;
      });
      return value;
    },
  },
  filters: {
    filterstwo(val) {
      return parseFloat(val).toFixed(2)
    },
    devicefilter(val) {
      if (val === '') {
        return '-'
      } else {
        return val
      }
    },
    datafilter(val) {
      if (val == null) {
        return '-'
      } else {
        return moment(val * 1000).format('MM-DD HH:mm:ss')
      }
    },
    pileDescribefilter(val) {
      if (val == null) {
        return '-'
      } else {
        return val
      }
    },
    depthfilter(val) {
      if (val == null) {
        return '-'
      } else {
        return val.toFixed(2)
      }
    }
  },
  mounted() {
    analysis(7).then((res) => {
      this.dataset.push(['1', '平米', '成桩'])
      let maxnum = []
      for (var i = res.result.length - 1; i >= 0; i--) {
        this.dataset.push([res.result[i].date, res.result[i].depth, res.result[i].pileAmount])
        maxnum.push(res.result[i].pileAmount)
      }
      this.maxAmount = (Math.max(...maxnum) * 1.5).toFixed(0)
      this.initEchart02();
    })

    historySum().then((res) => {
      this.swiperList = this.bubbleSort(res)
      for (let i = 0; i < res.length; i++) {
        this.swiperList[i].iconImage = require('@/assets/images/index/' + res[i].deviceType + '.png')
        if (res[i].deviceType == 'A_RD') {
          this.swiperList[i].swiperTypeName = '幅'
          this.swiperList[i].uint = '㎡'
        } else {
          this.swiperList[i].swiperTypeName = '根'
          this.swiperList[i].uint = 'm'
        }

      }
    })

    queryDevices(999, 1, '').then((res) => {
      this.data = []
      let flag = true;
      for (let j = 0; j < res.result.records.length; j++) {
        let icon;
        if (res.result.records[j].onlineStatus === 0) {
          this.online = '离线'
          // icon = require("@/assets/images/map/" + res.result.records[j].type + "b.png")
          icon = require("@/assets/images/map/b7.png")
        } else {
          this.online = '在线'
          // icon = require("@/assets/images/map/" + res.result.records[j].type + ".png")
          icon = require("@/assets/images/map/a6.png")
        }
        if (res.result.records[j].position == '') {
          res.result.records[j].position = '0,0'
        }
        if (parseFloat(res.result.records[j].position.split(',')[0]) !== 0 && parseFloat(res.result.records[j].position.split(',')[1]) !== 0 && flag) {
          this.center = [res.result.records[j].position.split(',')[0], res.result.records[j].position.split(',')[1]]
          flag = false
        }

        // 天地图数据
        let obj = {
          type: res.result.records[j].type,
          devicekey: res.result.records[j].key,
          itemid: res.result.records[j].name,
          status: res.result.records[j].status,
          pulp: res.result.records[j].realtime.totalSlurry,
          ash: res.result.records[j].realtime.totalAsh,
          icon: icon,
          position: [res.result.records[j].position.split(',')[0], res.result.records[j].position.split(',')[1]],
        }
        this.positions.push(obj)
      }

      let map = new Map();
      let color = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#80ff00', '#ffff00', '#00ffff', '#ff00ff', '#ff8080', ' #9966ff'];
      for (let j = 0; j < res.result.records.length; j++) {
        let deviceTypeName = res.result.records[j].typeName
        if (map.get(deviceTypeName) !== undefined) {
          let obj = map.get(deviceTypeName)
          // 判断设备是否在线
          //判断isMove是否为 1 离场
          if (res.result.records[j].isMove == 1) {
            obj.deviceLeave = obj.deviceLeave + 1
          } else {
            if (res.result.records[j].onlineStatus === 0) {
              obj.offLine = obj.offLine + 1
            } else {
              obj.onLine = obj.onLine + 1
            }
          }
          map.set(deviceTypeName, obj)
        } else {
          let obj = {};
          obj.name = deviceTypeName
          obj.offLine = 0
          obj.color = color[map.size]
          obj.onLine = 0
          obj.deviceLeave = 0
          if (res.result.records[j].isMove == 1) {
            obj.deviceLeave = obj.deviceLeave + 1
          } else {
            if (res.result.records[j].onlineStatus === 0) {
              obj.offLine = obj.offLine + 1
            } else {
              obj.onLine = obj.onLine + 1
            }
          }
          map.set(deviceTypeName, obj)
        }
      }
      let deviceCount = [];
      map.forEach(function (key) {
        deviceCount.push(key)
      })
      this.deviceData = deviceCount
      this.initEchart01();
    })

    newest().then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i]._id == undefined) {
          res[i].pileDescribe = '施工中 ' + res[i].pileDescribe
        }
      }
      this.tableData = res
      if (res.length <= 6) {
        this.swiperOption1.slidesPerView = res.length
      }
      this.swiper1.update();

      this.$nextTick(function () {
        this.swiper1.reLoop();
        this.swiper1.startAutoplay();
      })
    })
    let self = this;
    self.scale = Math.min(document.documentElement.clientWidth / 1920, 2);
    window.onresize = function () {
      self.scale = Math.min(document.documentElement.clientWidth / 1920, 2);
      self.myChart01.resize();
      self.myChart02.resize();
    };
  },
  methods: {
    initMap(map) {
      this.map = map;
    },
    openInfowindow({ target, extData }) {
      let item = extData;
      this.infowindow = {
        target,
        content:
          `<div 
          class="info-window"
        >
          <div class="content">
            <div class="blocktop">
              <div class="p">
                <span class="tit">设备：</span>
                <span class="value">${item.itemid || ''}</span>
              </div>
              <div class="p">
                <span class="tit">记录状态：</span>
                <span class="value">${item.status == 1 ? '离线' : '在线'}</span>
              </div>
              <div class="p">
                <span class="tit">累计浆量：</span>
                <span class="value">${item.pulp || 0}</span>
              </div>
              <div class="p">
                <span class="tit">累计灰量：</span>
                <span class="value">${item.ash || 0}</span>
              </div>
            </div>
            <div class="bottom">
              <div class="g-tips">
                <div class="li">
                  <router-link to="" class="tip" >
                    <span class="icon icon01"></span>
                    <a href="#/history">
                    <span class="value">历史记录</span></a>
                  </router-link>
                </div>
                <div class="li">
                  <router-link to="" class="tip">
                    <span class="icon icon02"></span>
                    <a href="#/previewOnline"><span class="value">实时数据</span></a>
                  </router-link>
                </div>
                <div class="li">
                  <router-link to="" class="tip">
                    <span class="icon icon03"></span>
                    <a href="#/alertInfo"><span class="value">预警信息</span></a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>`
      };
      let self = this;
      this.$nextTick(() => {
        $('body').on('click', '.info-window .js-close', () => {
          self.infowindow.target = null;
        });
      });
      sessionStorage['type'] = item.type
      sessionStorage['deviceKey'] = item.devicekey
    },
    infoWindowClose(index) {
      this.positions = this.positions.map((item, idx) => {
        item.show = false;
        return item;
      })
    },
    infoWindowOpen(index) {
      this.positions = this.positions.map((item, idx) => {
        if (index == idx) {
          item.show = true;
        } else {
          item.show = false;
        }
        return item;
      })
    },
    bubbleSort(arr) {
      var len = arr.length;
      for (var i = 0; i < len; i++) {
        for (var j = 0; j < len - 1 - i; j++) {
          if (Number(arr[j].total) < Number(arr[j + 1].total)) {        //相邻元素两两对比
            var temp = arr[j + 1];        //元素交换
            arr[j + 1] = arr[j];
            arr[j] = temp;
          }
        }
      }
      return arr;
    },

    initEchart01() {
      let data = this.deviceData.map((item) => {
        return {
          name: item.name,
          value: item.offLine + item.onLine + item.deviceLeave,
          itemStyle: {
            color: item.color,
          },

          children: [
            {
              name: "离线",
              itemStyle: {
                opacity: 0.45,
                color: item.color,
              },
              value: item.offLine,
            },
            {
              name: "在线",
              value: item.onLine,
              itemStyle: {
                color: item.color,
              },
            },
            {
              name: "离场",
              itemStyle: {
                opacity: 0.15,
                color: item.color,
              },
              value: item.deviceLeave,
            },
          ],
        };
      });
      let option = {
        series: {
          type: "sunburst",
          data: data,
          center: ['center', '50%'],
          radius: ["50%", "95%"],
          selectedMode: false,
          nodeClick: false,
          itemStyle: {
            borderWidth: 3,
            emphasis: {
              borderWidth: 3,
            },
          },
          levels: [
            {},
            {
              r0: "15%",
              r: "75%",
            },
            {
              r0: "77%",
              r: "100%",
            },
          ],
          emphasis: {
            focus: "none",
          },
          label: {
            show: true,
          },
        },
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01);
      // 绘制图表
      this.myChart01.setOption(option);
    },
    initEchart02() {
      let option = {
        legend: {
          right: 0,
          icon: "pin",
        },
        grid: {
          left: "8%",
          right: "8%",
          top: "20%",
          bottom: "10%",
        },
        tooltip: {
          trigger: "axis",
          // position: function (point, params, dom, rect, size) {
          //   // 固定在顶部
          //   return [point[0], "10%"];
          // },
        },
        dataset: {
          source: this.dataset,
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: "#88898D",
            fontSize: 13,
          },
        },
        yAxis: [
          {
            name: '平米',
            type: "value",
            axisLabel: {
              color: "#88898D",
              fontSize: 13,
            },
            axisLine: {
              // 设置y轴坐标轴 
              show: true
            },
          },
          {
            name: '成桩',
            type: "value",
            axisLabel: {
              color: "#88898D",
              fontSize: 13,
            },
            max: this.maxAmount,
            splitLine: {
              // 设置网格线
              show: false
            },
          }
        ],
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: "bar",
            barWidth: 12,
            color: "#0380FF",
            barGap: "60%",
            itemStyle: {
              borderRadius: 12,
            },
          },
          {
            type: "bar",
            barWidth: 12,
            yAxisIndex: 1,
            color: "#25D5CA",
            barGap: "60%",
            itemStyle: {
              borderRadius: 12,
            },
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02);
      // 绘制图表
      this.myChart02.setOption(option);
    },
    // 首页顶部的左右箭头
    prevHandle: function () {
      this.swiper.slidePrev();
    },
    nextHandle: function () {
      this.swiper.slideNext();
    },
  },
};
</script>

<style>
.swiperShigong {
  color: red;
}
.swiperRightBot {
  color: #1f2329;
}
.m-device-echart .el-table {
  padding: 10px 0;
  background: #f7f8fc;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.typeDiv1 {
  padding-left: 10.5%;
  float: left;
  width: 40%;
  font-size: 1rem;
  background: #f7f8fc;
  border-top-left-radius: 10px;
  padding-top: 4px;
}
.typeDiv2,
.typeDiv3,
.typeDiv4 {
  float: left;
  width: 20%;
  font-size: 1rem;
  background: #f7f8fc;
  padding-top: 4px;
}
.typeDiv4 {
  border-top-right-radius: 10px;
}
.m-device-echart .el-table {
  padding: 0 0 0.625rem;
}
/* 设置顶部图片左间距 */
.m-swiper .top {
  background-position-x: 0.5rem;
}
/* 文字太长省略 */
.i1pileName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 首页最新数据类型 */
.m-swiperb1 .i1:nth-child(1),
.m-swiperb1 .tit .item:nth-child(1) {
  width: 15%;
}
.m-swiperb1 .i1:nth-child(4),
.m-swiperb1 .tit .item:nth-child(4) {
  width: 16%;
}
.m-swiperb1 .i1:nth-child(2),
.m-swiperb1 .tit .item:nth-child(2) {
  width: 34%;
  height: 2.625rem;
}
.m-swiperb1 .i1:nth-child(3),
.m-swiperb1 .tit .item:nth-child(3) {
  width: 30%;
}
.main.el-main {
  padding-top: 0rem;
}
.m-content .el-row .el-col {
  padding: 0.25rem;
}
/* 设置首页饼图右边表格样式 */
.m-table .el-table {
  height: 86%;
}

.custom-marker {
  background-color: hsla(180, 0%, 57%, 0.7);
  height: 24px;
  width: 24px;
  border: 1px solid hsl(180, 0%, 57%, 0.7);
  border-radius: 12px;
  box-shadow: hsl(180, 0%, 57%, 0.7) 0px 0px 1px;
  text-align: center;
}

/* .custom-marker {
  background-color: hsla(180, 100%, 50%, 0.7);
  height: 24px;
  width: 24px;
  border: 1px solid hsl(180, 100%, 40%);
  border-radius: 12px;
  box-shadow: hsl(180, 100%, 50%) 0px 0px 1px;
  text-align: center;
} */

.el-card__header {
  padding: 0 0.5rem;
}
.el-card__body {
  padding: 1.25rem 1.25rem 0;
}
</style>
